import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import styles from './styles'

const RichText = ({
  localeBlocks,
  lang,
  className,
  styled,
  ...rest
}) => (
  <BlockContent
    className={cn(className, {[styles.rte]: styled}, {[styles.jp]: lang === 'jp'})}
    renderContainerOnSingleChild={true}
    blocks={lang === 'jp' ? localeBlocks._rawJp : localeBlocks._rawEn}
    {...rest}
  />
)

RichText.propTypes = {
  localeBlocks: PropTypes.object.isRequired,
  lang: PropTypes.string,
  className: PropTypes.string,
  styled: PropTypes.bool,
}

RichText.defaultProps = {
  lang: `en`,
  className: '',
  styled: true,
}

export default RichText
